<template>
    <b-row>
      <b-col sm="12" md="12">
        <b-card no-body>
            <table class="table">
            <thead>
                <tr>
                    <th style="width:40%">Name</th>
                    <th style="width:40%">NUmber</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Police</td>
                    <td> <b>112</b></td>
                </tr>
                <tr>
                    <td>Fire</td>
                    <td><b>101</b></td>
                </tr>
                <tr>
                    <td>Ambulance</td>
                    <td><b>108</b></td>
                </tr>
                <tr>
                    <td>Child Helpline</td>
                    <td><b>1098</b></td>
                </tr>
                <tr>
                    <td>Women Helpline</td>
                    <td><b>1091</b></td>
                </tr>
                <tr>
                    <td>Senior Citizens Helpline</td>
                    <td><b>1950</b></td>
                </tr>
                <tr>
                    <td>National Highways Helpline</td>
                    <td><b>1033</b></td>
                </tr>
                <tr>
                    <td>Railway Security Helpline</td>
                    <td><b>1322</b></td>
                </tr>
                <tr>
                    <td>Railway Enquiry</td>
                    <td><b>139</b></td>
                </tr>
                <tr>
                    <td>LPG Leak Helpline</td>
                    <td><b>1906</b></td>
                </tr>
            </tbody>
        </table>
        </b-card>
      </b-col>
    </b-row>
  </template>

  <script>
  import listaction from "@/views/Component/listaction.vue";
  import {
    BCard,
    BBadge,
    BRow,
    BCol,
    BFormInput,
    VBTooltip,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
  } from "bootstrap-vue";
  import { avatarText } from "@core/utils/filter";
  import vSelect from "vue-select";
  import { onUnmounted } from "@vue/composition-api";
  import store from "@/store";
  import useList from "./useList";
  import StoreModule from "./StoreModule";
  export default {
    directives: {
      "b-tooltip": VBTooltip,
    },
    components: {
      listaction,
      BCard,
      BBadge,
      BRow,
      BCol,
      BFormInput,
      VBTooltip,
      BButton,
      BAvatar,
      BTable,
      BMedia,
      BLink,
      BDropdown,
      BDropdownItem,
      BPagination,
      vSelect,
    },
    setup() {
      const GENAPP_APP_STORE_MODULE_NAME = "assistance-helplinenumber";
      const modulename = "Helpline Number ";
      const modulenamesub = "helplinenumber";
      // Register module
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
          store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
      });

      const {
        fetchDatas,
        deletefun,
        tableColumns,
        perPage,
        currentPage,
        totalDatas,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refDataListTable,
        statusFilter,
        refetchData,
        resolveDataRoleVariant,
      } = useList();
      return {
        modulename,
        modulenamesub,
        fetchDatas,
        deletefun,
        tableColumns,
        perPage,
        currentPage,
        totalDatas,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refDataListTable,
        statusFilter,
        refetchData,
        avatarText,
        resolveDataRoleVariant,
        GENAPP_APP_STORE_MODULE_NAME,
      };
    },
    methods: {
      checkdeletefun(val) {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.deletefun(val);
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: this.modulename + " has been deleted.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        });
      },
    },
  };
  </script>

  <style lang="scss" scoped>
  .per-page-selector {
    wicabletv: 90px;
  }
  .electricity-filter-select {
    min-wicabletv: 190px;
    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }
    ::v-deep .vs__selected {
      wicabletv: 100px;
    }
  }
  </style>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
